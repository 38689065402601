import { v4 as uuid } from 'uuid';
import { toastr } from 'react-redux-toastr';

import firebase from 'firebase.js';
import {
  SURVEYS_CREATE_SURVEY_REPONSE_FAIL,
  SURVEYS_CREATE_SURVEY_REPONSE_INIT,
  SURVEYS_CREATE_SURVEY_REPONSE_SUCCESS,
} from 'state/actionCreators/surveys';
import { firebaseError } from 'utils';
import { getGroupsForRegionalBreakdownChoices } from 'utils/actions';
import { responsesCollection } from 'utils/firebase/surveys';

export const createSurveyResponse = ({
  waveId,
  surveyId,
  deploymentId,
  questionResponses,
  demographics,
  organizationName,
}) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_CREATE_SURVEY_REPONSE_INIT());

    const { locale } = getState().preferences;

    const createdAt = firebase.firestore.Timestamp.fromDate(new Date());

    const groups = await getGroupsForRegionalBreakdownChoices(
      organizationName,
      questionResponses
    );

    const response = {
      userId: uuid(),
      demographics: demographics.length > 0 ? demographics : null,
      groups,
      valid: true,
      waveId,
      surveyId,
      deploymentId,
      questionResponses,
      createdAt,
    };

    // console.log('response', response);
    
    // debugger;

    try {
      await responsesCollection.add(response);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SURVEYS_CREATE_SURVEY_REPONSE_FAIL({
          error: errorMessage,
        })
      );
    }

    return dispatch(SURVEYS_CREATE_SURVEY_REPONSE_SUCCESS());
  };
};
