/* eslint-disable no-await-in-loop */
import { v4 as uuid } from 'uuid';
import { toastr } from 'react-redux-toastr';
import ShortUniqueId from 'short-unique-id';

import firebase from 'firebase.js';
import {
  SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_FAIL,
  SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_INIT,
  SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_SUCCESS,
} from 'state/actionCreators/surveys';
import { firebaseError } from 'utils';
import { getGroupsForRegionalBreakdownChoices } from 'utils/actions';
import { Collections } from 'utils/enums';
import { collection } from 'utils/firebase';

const DICTIONARY = [
  '0',
  // '1', removed due to possible confussion with l when copying the result in paper
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '-',
];

const { randomUUID } = new ShortUniqueId({
  length: 10,
  dictionary: DICTIONARY,
});

export const createSurveyResponseSave = ({
  waveId,
  surveyId,
  deploymentId,
  questionResponses,
  demographics,
  organizationName,
  pageIndex,
}) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_INIT());

    const { locale } = getState().preferences;

    const createdAt = firebase.firestore.Timestamp.fromDate(new Date());

    const groups = await getGroupsForRegionalBreakdownChoices(
      organizationName,
      questionResponses
    );

    const uniqueId = randomUUID();

    const response = {
      userId: uuid(),
      demographics: demographics.length > 0 ? demographics : null,
      groups,
      valid: true,
      waveId,
      surveyId,
      deploymentId,
      questionResponses,
      createdAt,
      pageIndex,
    };

    try {
      await collection(Collections.RESPONSES_IN_PROGRESS)
        .doc(uniqueId)
        .set(response);
    } catch (error) {
      console.error('Save Response in Progress:', error);
      const errorMessage = firebaseError(error.code, locale);

      toastr.error('', errorMessage);

      return dispatch(
        SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_FAIL({
          error: errorMessage,
        })
      );
    }

    return dispatch(
      SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_SUCCESS({
        responseCode: uniqueId,
      })
    );
  };
};
